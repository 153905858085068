import React from 'react';
import './App.css';
import Menu from './Components/Menu';
import Banner from './Components/Banner';
import About from './Components/About';
import Funcionalidades from './Components/Funcionalidades';
import Footer from './Components/Footer';

function App() {
  return (
    <div className="App">
      <Menu/>
      <Banner/>
      <About/>
      <Funcionalidades/>
      <Footer/>
    </div>
  );
}

export default App;
