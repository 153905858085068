import React from 'react';

import'./style.css';
import logo from '../../Assets/img/logo.svg'
function Menu() {
  return( 
  <div className="menu_wrapper">
      
      <div className="logo_wrapper">
          <img src={logo} alt="logo"/>
      </div>


      <div className="nav_wrapper"></div>

  </div>
  );
}

export default Menu;