import React from 'react';

import './style.css';

function Banner() {
  return (
        <div className="banner_wrapper">
            <div className="text_wrapper">
                <div className="text_container">
                    <div className="text">
                        <strong>Conheça o MAV Mail</strong>
                        <br/>
                        E-MAIL PROFISSIONAL PARA A SUA EMPRESA
                    </div>
                </div>
            </div>
            <div className="video_wrapper">
                <div className="video_container">
                <iframe title="video" width="700" height="415" src="https://www.youtube.com/embed/b68KmP1556E" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
        </div>
  );
}

export default Banner;