import React from 'react';

import './style.css';
import antispam from '../../Assets/img/antispam.png'
import arquivamento from '../../Assets/img/arquivamento.png'
import controle from '../../Assets/img/controle.png'
import backup from '../../Assets/img/backup.png'
import recibo from '../../Assets/img/recibo.png'



function Funcionalidades() {
  return (
  <div className="funcionalidades_wrapper">
      <div className="title">Funcionalidades</div>

      <div className='boxes_wrapper'>

          <div className="box">
              <div className="text_area">
              <div className="title_text">MAV Antispam</div>
                   <p> Garanta 99,9% de eficácia na proteção contra spams, fraudes e phishing (roubo de informações) que chegam por e-mail
                    diariamente. Para isso, são feitas análises de textos do e-mail, black list e assinaturas geradas automaticamente.
                    O bloqueio desses spams além de garantir a segurança também aumenta a produtividade, evitando perda de tempo com
                    e-mails que não são o foco do seu negócio.</p>
              </div>
              <div className="img_area">
                  <img src={antispam} alt="antispam" />
              </div>
          </div>


          <div className="box reverse">
              <div className="text_area">
              <div className="title_text">Arquivamento</div>
                   <p>Acesse o registro de todo o tráfego e conteúdo dos seus e-mails. Além do melhor monitoramento dos dados, é possível recuperar mensagens já excluídas pelos usuários e fazer o arquivamento, ou seja, a documentação dessas informações.</p>
              </div>
              <div className="img_area">
                  <img src={arquivamento} alt="antispam" />
              </div>
          </div>




          <div className="box">
              <div className="text_area">
              <div className="title_text">Controle De Acesso</div>
                   <p>Evite acessos inesperados que podem gerar horas extras de seus colaboradores ou aumentar o risco de vazamentos de informações confidenciais. Basta bloquear o acesso aos e-mails fora do horário comercial ou da rede da empresa.</p>
              </div>
              <div className="img_area">
                  <img src={controle} alt="antispam" />
              </div>
          </div>


          <div className="box reverse">
              <div className="text_area">
              <div className="title_text">Backup</div>
                   <p>Tenha cópias de segurança de todos os e-mails que circulam na empresa e recupere qualquer mensagem, de maneira imediata. Esses e-mails não podem ser alterados ou apagados e ficam armazenados até 10 anos.</p>
              </div>
              <div className="img_area">
                  <img src={backup} alt="antispam" />
              </div>
          </div>


          <div className="box">
              <div className="text_area">
              <div className="title_text">Recibo De Entrega</div>
                   <p>Identifique problemas no envio de e-mails e evite falhas na comunicação com seu cliente. Disponibilizamos relatórios para que você cheque se um e-mail saiu da sua caixa de saída ou parou no servidor do destinatário.</p>
              </div>
              <div className="img_area">
                  <img src={recibo} alt="antispam" />
              </div>
          </div>

      </div>

  </div>
  );
}

export default Funcionalidades;