import React from 'react';

import './style.css';

function About() {
  return (<div className="about_wrapper">
      <div className="title">MAV Tecnologia</div>

      <div className="boxes_wrapper">
          <div className="box">
              <div className="title_box">Pesquisa e desenvolvimento</div>
              <p>Somos uma empresa genuinamente brasileira, que dedica 20 mil horas por ano na constante melhoria de nossos produtos e no desenvolvimento de novas soluções.</p>
          </div>

          <div className="box">
              <div className="title_box">Escalabilidade dos produtos</div>
              <p>Ferramentas múltiplas e completas compõem nosso portfólio de produtos e atendem integralmente nossos clientes à medida em que novas necessidades vão surgindo.</p>
          </div>

          <div className="box">
              <div className="title_box">Melhor relação custo x benefício</div>
              <p>Estamos na vanguarda da inovação. E como desenvolvemos nossos próprios produtos, oferecemos as melhores soluções com os preços mais competitivos do mercado.</p>
          </div>

          <div className="box">
              <div className="title_box">Atendimento técnico personalizado</div>
              <p>Nossa equipe interna e nossos canais de comunicação (telefone, e-mail, WhatsApp, Skype e mídias sociais) estão à disposição dos clientes, para auxiliá-los em todas as suas demandas.</p>
          </div>

      </div>
  </div>);
}

export default About;