import React from 'react';

import'./style.css';

function Footer() {
  return (
  <div className="footer_wrapper">
      <div className="contact">
          <p>Precisa de ajuda?<strong>Ligue para (31) 3211-7777</strong> para falar com o nosso comercial ou <a href="http://mav.com.br/agenda">Clique aqui</a>  e agende uma apresentação completa!</p>
          
          </div>
        <div className="rights">Todos os direitos reservados @2019 – MAV Tecnologia</div>
  </div>
  );
}

export default Footer;